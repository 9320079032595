import { emitter } from '../event'
import { useAppStore } from '@/store/app'
import { useTenantStore } from '@/store/tenant'
import { useSystemStore } from '@/store/system'
import { registerEvents } from './event-register'
import Pusher from 'pusher-js'
import { getApiUrl } from '../apiUrl'

Pusher.logToConsole = false

export function getPusher(): Pusher {
	const grayOption = import.meta.env.VITE_IS_GRAY
		? {
			'X-Gray': 'true',
		}
		: {}

	const pusherInstance = new Pusher(import.meta.env.VITE_PUSHER_KEY || '90915926774e1fee4451', {
		cluster: import.meta.env.VITE_PUSHER_CLUSTER || 'mt1',
		channelAuthorization: {
			endpoint: `${getApiUrl()}/api/frontend/pusher/channel-auth`,
			transport: 'ajax',
			headersProvider: () => {
				const appStore = useAppStore()
				const tenantStore = useTenantStore()
				return {
					'Authorization': `Bearer ${appStore.token}`,
					'Client-Language': appStore.locale || 'zh-CN',
					'tenantId': tenantStore.tenantId,
					...grayOption,
				}
			},
		},
		userAuthentication: {
			endpoint: `${getApiUrl()}/api/frontend/pusher/user-auth`,
			transport: 'ajax',
			params: {},
			headers: {},
			paramsProvider: undefined,
			headersProvider: () => {
				const appStore = useAppStore()
				const tenantStore = useTenantStore()
				const systemStore = useSystemStore()
				return {
					'Authorization': `Bearer ${appStore.token}`,
					'tenantId': tenantStore.tenantId,
					'Client-Language': appStore.locale || 'zh-CN',
					'X-Device-Type': systemStore.app?.build || 'AndroidH5',
					...grayOption,
				}
			},
		},
	})

	//注册事件
	registerEvents(pusherInstance)

	return pusherInstance
}

export async function initPusher() {
	const appStore = useAppStore()
	if (!appStore.token) {
		return
	}
	const pusher = getPusher()
	const tenantStore = useTenantStore()
	pusher.signin()
	pusher.subscribe(`private-${tenantStore.tenantId}`)
}

export async function disconnectPusher() {
	Pusher.instances.forEach((pusher) => {
		pusher.disconnect()
	})
}

export function registerPusherEvents() {
	emitter.on('system/init-finish', async (data) => {
		await initPusher()
	})

	emitter.on('user/login', async (data) => {
		await initPusher()
	})

	emitter.on('user/logout', (data) => {
		disconnectPusher()
	})

	emitter.on('system/exit', () => {
		disconnectPusher()
	})
}
