import { defineStore } from 'pinia'
import { useAppStore } from '@/store/app';
import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';
import { App, AppInfo } from '@capacitor/app';
import { BROWSER, OS, PLATFORM, SD_MODEL } from '@/enums/device';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import { storeDefaultValue } from '@/hooks/StoreDefaultValue';
import { ApkSd, PwaAllowPcBrowser, PwaAllowSd } from '@/common/data';
import UAParser from 'ua-parser-js';
import { isInAppBrowser } from '@/utils/app';
import { AppTypes } from '@/enums/types';
export const useSystemStore = defineStore({
  id: 'system',
  state: () => ({
    os: '',                             // 平台系统类型
    browser: '',                        // 浏览器
    deviceId: '',                       // 设备id
    modalType: '',                      // 弹窗类型
    deviceModel: '',                    // 设备信息
    platform: Capacitor.getPlatform(),  // 平台
    isInOutApp: false,  // 是否在外部app内 比如facebook
    outAppType: '' ,    // 外部app类型 ios android
    app: storeDefaultValue<AppInfo>(),  // app信息
  }),

  getters: {
    // 是否为App(含PWA)
    isApp(): boolean {
      if (this.app?.build) {
        return !PwaAllowSd.includes(this.app.build);
      } else {
        return false;
      }
    },
    // 是否为可安装PWA环境
    isPwaVisible(): boolean {
      // AndroidH5 应许安装pwa的浏览器
      let pwaAllowBrowser = [BROWSER.CHROME]
      if (this.browser === BROWSER.MOBILE_SAFARI || (this.app?.build === SD_MODEL.ANDROID_H5 && pwaAllowBrowser.includes(this.browser as BROWSER)) || (this.app?.build === SD_MODEL.DESKTOP_OS && PwaAllowPcBrowser.includes(this.browser)))
        return true;
      return false;
    },
    // 是否ios
    isIOS(): boolean {
      return this.os === 'iOS';
    },
    // 是否PWA环境
    isPwa(): boolean {
      return this.app?.build === SD_MODEL.PWA;
    },
    // 是否APK环境
    isApk(): boolean {
      return ApkSd.includes(`${this.app?.build}`);
    },
  },

  actions: {
    // 设置app信息
    async setAppInfo(route?: RouteLocationNormalizedLoaded) {
      if (this.platform === PLATFORM.WEB) {
        const parser = new UAParser();
        const result = parser.getResult();
        this.browser = result.browser.name;
        this.os = result.os.name;
        // if (isInAppBrowser()) {
        //   let build = this.os === 'Android' ? SD_MODEL.OUT_APP_ANDROID : SD_MODEL.OUT_APP_IOS;
        //   this.app = { build, version: '', name: '', id: '' };

        //   return
        // }


        if (route && route.query.sd) {
          this.app = { build: AppTypes[Number(route.query.sd)], version: '', name: '', id: '' };
        } else {

          if (('standalone' in navigator && navigator.standalone) || window.matchMedia('(display-mode: standalone)').matches) {
            this.app = { build: SD_MODEL.PWA, version: '', name: '', id: '' };
          } else if (result.os.name === OS.IOS) {
            this.app = { build: SD_MODEL.IOS_H5, version: result.os.version, name: '', id: '' };
          } else if (result.os.name === OS.ANDROID) {
            this.app = { build: SD_MODEL.ANDROID_H5, version: result.os.version, name: '', id: '' };
          } else {
            this.app = { build: SD_MODEL.DESKTOP_OS, version: result.os.version, name: '', id: '' };
          }
          if (this.browser === BROWSER.MOBILE_SAFARI) {
            const appStore = useAppStore();
            appStore.setUnStandalone(true);
          }
        }

        // if (('standalone' in navigator && navigator.standalone) || window.matchMedia('(display-mode: standalone)').matches) {
        //   this.app = { build: SD_MODEL.PWA, version: '', name: '', id: '' };
        // } else if (result.os.name === OS.IOS) {
        //   this.app = { build: SD_MODEL.IOS_H5, version: result.os.version, name: '', id: '' };
        // } else if (result.os.name === OS.ANDROID) {
        //   this.app = { build: SD_MODEL.ANDROID_H5, version: result.os.version, name: '', id: '' };
        // } else {
        //   this.app = { build: SD_MODEL.DESKTOP_OS, version: result.os.version, name: '', id: '' };
        // }
        // if (this.browser === BROWSER.MOBILE_SAFARI) {
        //   const appStore = useAppStore();
        //   appStore.setUnStandalone(true);
        // }

      } else {
        this.app = await App.getInfo();
        if (this.platform === PLATFORM.ANDROID) {
          this.app.build = SD_MODEL.APK;
        } else {
          this.app.build = SD_MODEL.IOS_APP;
        }
      }
    },

    // 设置设备信息
    async setDeviceInfo() {
      this.deviceId = (await Device.getId()).identifier;
      const info = await Device.getInfo();
      if (info.platform === PLATFORM.WEB) {
        this.deviceModel = `${info.platform} ${info.model} ${info.operatingSystem} ${info.osVersion}`;
      } else {
        this.deviceModel = `${info.model} ${info.operatingSystem} ${info.osVersion}`;
      };
    },

    // 设置弹窗类型
    setModalType(type: string) {
      this.modalType = type;
    },
  },
})
