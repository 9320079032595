import { createApp } from 'vue'
import { createPinia } from 'pinia';
import { IonicVue } from '@ionic/vue';
import VueLuckyCanvas from '@lucky-canvas/vue'
import FlagIcon from 'vue-flag-icon';
import router from './router';
import App from './App.vue'
import i18n from './i18n';
import { domainInfoApi } from '@/api/normal';
import { buildUrlParam } from './hooks/BuildUrlParam';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import { generateManifestProtocolHandler } from './utils/pwa/manifest';
import { getWebDomain } from '@/utils/app';
import { getUrlParams } from '@/hooks/HttpCompletion';
/* Theme variables */
import './theme/variables.css';

import './common/theme.less'


//  三星浏览器必须要在浏览器解析html中head要有manifest.json文件
// 并且三星浏览用js更新manifest.json无效。因为有这两个特征，我们现在的写法三星浏览器无法安装pwa
// 单独对三星浏览器的manifestJson特殊处理，
const samsungBrowserManifest = async () => {
  let appInfo = window.sessionStorage.getItem('appInfo')
  let domain = window.sessionStorage.getItem('domain')
  let host = getWebDomain()
  if (host.includes('localhost') || host.includes('5173')) {
    host = 'bc.qu6.xyz'
  }
  if (!appInfo || !domain || domain !== host) {

    let { configList, info
    } = await domainInfoApi(host)
    let { appIcon, siteName } = configList
    window.sessionStorage.setItem('domain', host)
    window.sessionStorage.setItem("appInfo", JSON.stringify({ tenantId: info.tenantId, appIcon, siteName }))
    // 三星浏览用js更新manifest.json无效，所以刷新页面
    location.reload()
    return
  }

  let { tenantId, appIcon, siteName } = JSON.parse(appInfo)
  let token = window.sessionStorage.getItem('token')
  let accountInfo = window.sessionStorage.getItem('accountInfo')
  let acc = ''	// 获取账号
  let pass = '' // 获取密码
  if (accountInfo) {
    let result = JSON.parse(accountInfo)
    acc = result.acc
    pass = result.pwd
  }
  let urlParams = getUrlParams(window.location.href) || {}



  const params = buildUrlParam({
    // 构建url参数
    ...urlParams,
    token,
    acc,
    pass,
    sd: 2,

  });

  // 协议处理程序
  const start_url = `${location.origin}${location.pathname}${params}`; // PWA启动url

  const protocolName = siteName || tenantId;
  const protocol_handlers = [generateManifestProtocolHandler(protocolName, start_url)];
  console.log('333start_url====', start_url, 'location.origin====', location.origin);
  const manifest = {
    theme_color: "black",
    background_color: "black",
    display: "standalone",
    // orientation: "portrait",
    prefer_related_applications: false,
    related_applications: [],
    id: `${location.origin}/${tenantId}`,
    name: `${siteName}`,
    scope: `${location.origin}/?sd=2`,
    short_name: `${siteName}`,
    start_url,
    icons: [
      {
        src: `${appIcon}`,
        sizes: "192x192",
        type: "image/png",
      },
      {
        src: `${appIcon}`,
        sizes: "512x512",
        type: "image/png",
      },
    ],
    protocol_handlers,
  }


  // 将 manifest 对象转换为 JSON 字符串
  const manifestJson = JSON.stringify(manifest, null, 2);

  // 将 manifestData 转换为 Blob 并生成 URL
  //  const blob = new Blob([manifestJson], { type: 'application/json' });
  //  const manifestURL = URL.createObjectURL(blob);


  // 更新页面中的 manifest link
  let link = document.querySelector('link[rel="manifest"]');

  if (!link) {
    link = document.createElement("link");
    link.rel = "manifest";
    document.head.appendChild(link);
  }
  link.href = "data:text/json;charset=utf-8," + encodeURIComponent(manifestJson)
  if (!(urlParams?.sd === 2 && urlParams?.token === token && urlParams?.acc === acc && urlParams?.pass === pass)) {
    router.replace({ path: location.pathname, query: { ...urlParams, sd: 2 } })
    return

  }

}


const pinia = createPinia();

const app = createApp(App)
  .use(VueLuckyCanvas)
  .use(IonicVue)
  .use(pinia)
  .use(i18n)
  .use(FlagIcon)
  .use(router);

// samsungBrowserManifest()
// 三星浏览器，要先加载manifest.json文件
if (window.isSamsungBrowser()) {
  // samsungBrowserManifest()
}


router.isReady().then(() => {
  //@ts-ignore
  window.isOpenMainUI = true
  app.mount('#app');
});
