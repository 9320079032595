import { BROWSER, OS, SOCIAL } from "@/enums/device";
import { useSystemStore } from "@/store/system";
import packageJson from "../../package.json";
import axios from "axios";

/**
 * @description 获取当前的构建版本号
 */
export function getBuildVersion(): string {
  return packageJson.version ?? "未知版本";
}

/**
 * @description 获取当前的域名
 */
export function getWebDomain(): string {
  const domain = import.meta.env.VITE_TRPC_HOST || window.location.host;
  if (domain.startsWith("www.")) return domain.replace("www.", "");
  return domain;
}

/**
 * @description 获取当前的完整域名
 */
export function getFullWebDomain(): string {
  return import.meta.env.VITE_TRPC_HOST || window.location.host;
}

/**
 * @description 跳转到指定URL
 * @param url 跳转网址
 */
export function openUrl(url: string, type?: string) {
  const systemStore = useSystemStore();
  const androidKwai = systemStore.os === OS.ANDROID && type === SOCIAL.KWAI;
  const isIosApk = systemStore.browser === BROWSER.WEBKIT;
  // import.meta.env.SWITCH_OPEN_WINDOW
  // 安卓/苹果 上架包
  if (systemStore.isApk || isIosApk) openWindow(url);
  // 安卓快手使用window.open
  else if (androidKwai) window.open(url, "_blank");
  else window.location.href = url;
}

/**
 * 判断URL是否为代理链接
 * @param url 要判断的URL
 * @returns 是否为代理链接
 */
export function isProxyUrl(url: string): boolean {
  try {
    return new URL(url).searchParams.has('pid');
  } catch (error) {
    console.error('Invalid URL:', error);
    return false;
  }
}



/**
 * 在URL链接的点号后面增加一个零宽度的空格符号，防止被识别为链接
 * @param url URL链接
 * @returns 处理后的URL链接
 */
export function addZeroWidthSpace(url: string): string {
  const dotIndices = [...url.matchAll(/\./g)].map((match) => match.index);
  let modifiedUrl = url;
  for (let i = dotIndices.length - 1; i >= 0; i--) {
    modifiedUrl = modifiedUrl.slice(0, dotIndices[i] + 1) + "\u200B" + modifiedUrl.slice(dotIndices[i] + 1);
  }
  return modifiedUrl;
}

/**
 * 调用apk openWindow事件
 * @param url
 * @returns
 * @param url 跳转网址
 */
export function openWindow(url: string) {
  const systemStore = useSystemStore(); // 引入 systemStore
  // @ts-ignore
  if (window.jsBridge) {
    const info = { url: url };
    if (systemStore.browser === BROWSER.WEBKIT) {
      window.open(url, "_blank");
      return;
    }
    const jsonStr = JSON.stringify(info);
    // @ts-ignore
    window.jsBridge.postMessage("openWindow", jsonStr);
  } else {
    window.open(url, "_blank");
  }
}

/**
 * 是否是线上环境
 */
export function isProd(): boolean {
  return import.meta.env.VITE_ENV === "prod";
}

/**
 * 是否为灰度环境
 */
export function isGray(): boolean {
  return import.meta.env.VITE_IS_GRAY;
}

/**
 * 是否为开发环境
 */
export function isDev(): boolean {
  return import.meta.env.VITE_ENV === "dev";
}

/**
 * @description 跳转到指定URL
 */
export function redirectUrl(url: string, social?: string) {
  const systemStore = useSystemStore();

  // import.meta.env.SWITCH_OPEN_WINDOW
  if (systemStore.isApk || systemStore.browser === BROWSER.WEBKIT || social === SOCIAL.KWAI) openWindow(url);
  else location.href = url;
}

/**
 * @description jsBridge原生方法初始化
 */
export function jsBridgeInit() {
  if (!(window.jsBridge && window.jsBridge.postMessage) && window.Android) {
    if (!window.jsBridge) window.jsBridge = {};
    window.jsBridge.postMessage = (evt, msg) => {
      switch (evt) {
        case "openWindow":
          const j = JSON.parse(msg);
          if (j.url && j.url.indexOf("http") === 0) {
            if (window.Android?.openAndroid) {
              window.Android?.openAndroid(j.url);
            } else {
              window.open(j.url, "_blank");
            }
          }
          break;
        default:
          window.Android?.eventTracker && window.Android?.eventTracker(evt, msg);
      }
    };
  }
}

export const isInAppBrowser = () => {
  try {
    const userAgent = window.navigator.userAgent;
    const list = [
      "wv",
      "Line/",
      "FBAN",
      "FBBV",
      "FBAV",
      "FB_IAB",
      "Instagram",
      "MicroMessenger",
      "Twitter",
      "Kakao",
      "KAKAO",
      'Tiktok',
      'TikTokWebView',
      'Kwai',
      'KwaiWebView'
    ];

    return list.some((item) => userAgent.includes(item));
  } catch (a) {
    return false;
  }
};

// 声明全局类型: Window
declare global {
  interface Window {
    jsBridge?: {
      postMessage?: (evt: string, msg: string) => void;
    };
    Android?: {
      openAndroid: (url: string) => void;
      eventTracker?: (evt: string, msg: string) => void;
    };
  }
}
